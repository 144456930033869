/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import qs from 'qs';
import { Spin, Icon } from 'antd';
import useStyles from './styles';

const NegoIframe = ({
  url,
  apiUrl,
  apiKey,
  orgId,
  identity,
  name,
  email,
  brandId,
  initMessage,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const refIframe = useRef(null);
  useEffect(() => {
    if (!refIframe) {
      return;
    }
    const data = {
      organisation_id: orgId,
      brand_id: brandId,
      customer_name: name,
      customer_email: email,
      text: initMessage,
    };
    refIframe.current.contentWindow.postMessage(JSON.stringify(data), '*');
  }, [orgId, name, email, brandId, initMessage, loading]);

  const params = qs.stringify({
    apiUrl,
    apiKey,
    identity,
  });
  const iframeUrl = `${url}?${params}`;

  return (
    <Spin
      wrapperClassName={classes.spinner}
      tip="Loading chat..."
      spinning={loading}
      indicator={
        <Icon
          type="loading"
          style={{
            fontSize: 24,
          }}
          spin
        />
      }
    >
      <iframe
        className={classes.iframe}
        title="nego"
        ref={refIframe}
        src={iframeUrl}
        onLoad={() => {
          setLoading(false);
        }}
      />
    </Spin>
  );
};

export default NegoIframe;

import configdefault from './config';
import configdev from './config.dev';
import configstaging from './config.staging';

const env = process.env.REACT_APP_ENV;

const evnConfig = {
  dev: configdev,
  staging: configstaging,
};

const config = {
  ...configdefault,
  ...evnConfig[env],
};

export default config;

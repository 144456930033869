import React from 'react';
import qs from 'qs';
import { useLocation } from 'dva';
import NegoIframe from './components/NegoIframe';
import configs from '../../configs';
import useStyles from './styles';

const Home = () => {
  const classes = useStyles();
  const location = useLocation();
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  const {
    organisation_id: orgId,
    id: identity,
    api_url: apiUrl,
    api_key: apiKey,
    brand_id: brandId,
    customer_name: name,
    customer_email: email,
  } = params;
  return (
    <div className={classes.root}>
      <NegoIframe
        url={configs.CHAT_IFRAME}
        orgId={orgId}
        identity={identity}
        apiUrl={apiUrl}
        apiKey={apiKey}
        brandId={brandId}
        name={name}
        email={email}
        initMessage="Epd Enquiry"
      />
    </div>
  );
};

export default Home;

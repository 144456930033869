import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    width: '100%',
    height: '100vh',
  },
});

export default useStyles;
